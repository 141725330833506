import React from "react";
import img from "./img/bg.avif";
import imgJpg from "./img/bg.jpg";
import { Link } from "react-router-dom";
import "./Home.css";

const Home = () => {
  const url = window.location.hostname;
  const caproig = "caproig";
  const pedralbes = "pedralbes";
  let text = false;
  let castellano = false;
  let sinregalo = false;
  let conregalo = false;
  let conregalosorteoalma= false;
  let promoFinalizada = false;
  const iconica = "iconica";
  const universal = "universal";
  const festivalalma = "festivalalma";
  const sorteoalma = "sorteofestivalalma";
  const forms = "forms";

  if(url.includes(iconica) || url.includes(universal) || url.includes(forms)) {
    castellano = true;
  }
  if(url.includes(festivalalma) || url.includes(caproig) || url.includes(pedralbes)) {
    conregalo = true;
    if(url.includes(sorteoalma))
    {
      conregalosorteoalma = true;
    }
  }

  return (
    <div className="home-container">
      <div>
        {promoFinalizada ?
        <>
            {castellano ?
              <>
                <div className="title-container">
                  <h1>La promoción ha finalizado</h1>
                </div>
              </>
              :
              <>
                <div className="title-container">
                  <h1>La promoció ha finalitzat</h1>
                  <p>La promoción ha finalizado</p>
                </div>
              </>
            }
        </>
        :
        <>
          {text ?
            <>
              <div className="title-container">
                <h1>vols lluir una pell radiant aquest estiu?</h1>
                <p>¿quieres lucir una piel radiante este verano?</p>
              </div>
              <div className="text-container">
                <p>
                Emplena aquest breu formulari per a aconseguir el tractament i un protector solar de regal.
                </p>
                <p>
                Completa este breve formulario para conseguir el tratamiento y un protector solar de regalo.
                </p>
              </div>
            </>
            : castellano ?
            <>
              <div className="title-container">
                  <h1>¿quieres lucir una piel radiante este verano?</h1>
                  <div className="text-container">
                    <p>
                    Completa este breve formulario para conseguir el tratamiento y un protector solar de regalo.
                    </p>
                  </div>
              </div>
              {sinregalo ? (
                <>
                <div className="text-container">
                  
                  <p>Rellena este breve formulario para conseguir  un tratamiento profesional{" "}
                    <em><strong>summer glow</strong></em>{" "} a un precio especial de 40€ y un protector solar de regalo.
                    {" "}</p>
              </div>
                </>
              ) : (
                <>
               
                </>
              )}  
            </>
            : 
            <>
              {conregalo ? (
                  <>
                  {conregalosorteoalma ? (
                    <>
                    <div className="title-container">
                      <h1>Participa per a aconseguir el nostre tractament exclusiu <strong>summer glow!</strong></h1>
                      <p>¡Participa para conseguir nuestro tratamiento exclusivo <strong>summer glow!</strong></p>
                    </div>
                    <div className="text-container" style={{ marginBottom: "15px" }}>
                      <p>Emplena el formulari i participa en el sorteig d'un tractament gratuït i un protector solar de regal.</p>
                      <p>Rellena el formulario y participa en el sorteo de un tratamiento gratuito y un protector solar de regalo.</p>
                    </div>
                    </>
                  ) : (
                    <>
                    <div className="title-container">
                    <h1>Participa per a aconseguir el nostre tractament exclusiu <strong>summer glow!</strong></h1>
                    <p>¡Participa para conseguir nuestro tratamiento exclusivo <strong>summer glow!</strong></p>
                  </div>
                  <div className="text-container" style={{ marginBottom: "15px" }}>
                    <p>Completa aquest breu formulari per a accedir a la promoció exclusiva del tractament estètic SUMMER GLOW i emportar-te un protector solar de regal al centre.</p>
                    <p>Rellena este breve formulario para acceder a la promoción exclusiva del tratamiento estético SUMMER GLOW y recibe un protector solar de regalo en el centro.</p>
                  </div>
                    </>
                  )}
                  </>
                ) : (
                  <>
                  <div className="title-container">
                    <h1>vols lluïr una pell radiant aquest estiu?</h1>
                    <p>¿quieres lucir una piel radiante este verano?</p>
                  </div>
                  <div className="text-container" style={{ marginBottom: "15px" }}>
                    <p>
                    Emplena aquest breu formulari per a aconseguir el tractament i un protector solar de regal.
                    </p>
                    <p>
                    Completa este breve formulario para conseguir el tratamiento y un protector solar de regalo.
                    </p>
                  </div>
                  </>
                )}
            </>
          }
          <div className="image-container">
            <picture>
              <source srcSet={img} type="image/avif" />
              <source srcSet={imgJpg} type="image/jpeg" />
              <img src={img} alt="Mesoestetic products" />
            </picture>
          </div>
          {castellano ?
            <>
              <Link to="/questionnaire">
                <div className="primary-btn">
                  <span>¡Empezar!</span>
                </div>
              </Link>
            </>
            :
            <>
              <Link to="/questionnaire">
                <div className="primary-btn">
                  <span>Començar!</span> /¡Empezar!
                </div>
              </Link>
            </>
          }
        </>          
        }
        
      </div>
    </div>
  );
};

export default Home;
